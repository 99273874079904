import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import styles from './index.module.less';

const PageHeader = props => (
  <header className={styles.root}>
    <Grid fluid>
      <Row>
        <Col md={8}>
          <h1 className={styles.title}>{props.title}</h1>
          {props.intro && <p className={styles.intro}>{props.intro}</p>}
        </Col>
      </Row>
    </Grid>
  </header>
);

export default PageHeader;
