import React from 'react';
import Helmet from 'react-helmet';
import { Grid, Row, Col } from 'react-flexbox-grid';
import PageHeader from 'components/PageHeader';
import { Paragraph } from 'components/Typography';
import styles from './index.module.less';
import image1 from 'images/project-3a.png';
import image2 from 'images/project-3b.png';
import image3 from 'images/project-3c.png';
import Layout from 'components/Layout';

const Page = () => (
  <Layout>
    <Helmet
      title="Cabins Provide Comfort"
      meta={[
        {
          name: 'description',
          content:
            'Young building apprentices learn valuable skills constructing Ryhaven Trust cabins.'
        }
      ]}>
      <html lang="en" />
    </Helmet>
    <PageHeader
      title="Cabins Provide Comfort"
      intro="Young building apprentices learn valuable skills constructing Ryhaven Trust cabins."
    />
    <Grid fluid>
      <Row>
        <Col sm={8} className={styles.projectText}>
          <Paragraph>
            Four young apprentices in Lower Hutt are busy building small wooden cabins that will be
            used to provide temporary accommodation for Ryhaven Trust families.
          </Paragraph>
          <Paragraph>
            Construction is taking place under the supervision of an experienced builder who is
            teaching these young upstarts useful skills to help them gain a building qualification.
            The assessment includes a residential component and building the cabins helps the
            students to meet criteria. The guys are seeing the job through from start to finish and
            there's certainly a sense of satisfaction when the cabins are complete.
          </Paragraph>
          <Paragraph>
            Whilst the majority of these structures will be used as temporary accommodation (when
            repair work is being carried out on family's main home) in some situations, where
            overcrowding is a problem, the cabins will be installed permanently. Two teenage
            brothers in Porirua, currently sharing a cabin, have remarked how warm they are during
            winter months.
          </Paragraph>
          <Paragraph>
            These photos document the process of installing a cabin on a section. It&rsquo;s a
            tricky process and impressive to watch! First, the cabin must be transported on the back
            of a truck, then lifted over the boundary fence with a crane and finally, set up with
            utilities.
          </Paragraph>
          <Paragraph>
            These cabins are meeting a real need for families in our community and help take the
            pressure off whilst homes are being repaired.
          </Paragraph>
        </Col>
        <Col sm={4} className={styles.projectImages}>
          <img className={styles.image} src={image1} alt="" />
          <img className={styles.image} src={image2} alt="" />
          <img className={styles.image} src={image3} alt="" />
        </Col>
      </Row>
    </Grid>
  </Layout>
);

export default Page;
